import tokenService from "@/services/identity/tokens.js"

import { useIdentityStore } from "@/stores/identity";

let identityStore = null;

export function vuexIdentityCreateRouterMiddleware(to, from, next) {
    if (identityStore === null) identityStore = useIdentityStore();

    let meta = to.meta;
    if (!meta.isPublic) {
        if (from.name === null || from.name === undefined) { // First page access            
            let token = tokenService.readTokenFromStorage();
            if (token !== undefined) {
                identityStore.addToken({ token });
            }
        }

        if (!allow(to, identityStore.getUser)) next({ name: 'unauthorized' });

        let isAuthenticated = identityStore.isAuthenticated;
        if (!isAuthenticated) {
            next({
                name: 'login'
            });
        } else {
            next();
        }
    } else {
        next();
    }
}

function allow(route, user) {
    let roles = route.meta?.roles;

    if (roles === null || roles === undefined || roles.length === 0) return true;

    for (const role of roles) {
        if (user.role === role || user.role.indexOf(role) > -1) return true;
    }

    return false;
}