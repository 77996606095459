import Repository from "./repository"

import { buildPaginationQuery } from "../pagination/index";

const resource = "league"

export default {
    async add(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async update({ id, payload }) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/${id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async setFavorite(payload) {
        let id = payload.id;
        let endpoint = `${resource}/${id}/favorite`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getAll(category) {
        let endpoint = `${resource}/all`;
        if (category !== undefined && category !== null) {
            endpoint += `?category=${category}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPaged(params) {
        let endpoint = `${resource}/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let category = params.category;
        if (category !== undefined && category !== "" && category !== null) {
            endpoint += `&category=${category}`;
        }
        let favorite = params.favorite;
        if (favorite !== undefined && favorite !== null) {
            endpoint += `&favorite=${favorite}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async export(id){
        let endpoint = `${resource}/${id}/export`;
        let response = await Repository.getFile(endpoint);
        return response;
    },
    async getImportCommands(payload){
        let endpoint = `${resource}/import/commands`;
        let response = await Repository.post(endpoint, payload);
        return response;
    }
}