import { createRouter, createWebHistory } from 'vue-router'

import { vuexIdentityCreateRouterMiddleware } from './middlewares.js'

import { roles } from "@/config/roles";

function parseNumber(input) {
  if (input === undefined || input === null || input === "") return null;

  return Number(input);
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/scouts/Scouts.vue')
  },
  {
    path: '/scouts',
    name: 'scouts',
    component: () => import('../views/scouts/Scouts.vue')
  },
  {
    path: "/scout/:playerId/:scoutId?",
    name: "scout",
    component: () => import("../views/scouts/PlayerScout.vue"),
    props: true,
  },
  {
    path: "/matches",
    name: "matches",
    component: () => import('../views/matches/MatchesSearch.vue')
  },
  {
    path: "/search",
    name: "search",
    component: () => import('../views/scouts/Search.vue')
  },
  {
    path: "/players",
    name: "players",
    component: () => import('../views/players/Players.vue')
  },
  {
    path: '/player/:playerId',
    name: 'player',
    component: () => import('../views/players/Player.vue'),
    props: true
  },
  {
    path: "/player/create",
    name: "create-player",
    component: () => import('../views/players/CreatePlayer.vue')
  },
  {
    path: "/player/:id/edit",
    name: "edit-player",
    component: () => import("../views/players/EditPlayer.vue"),
    props: true
  },
  {
    path: "/player/:playerId/global",
    name: "player-average",
    component: () => import("../views/scouts/PlayerAverageScout.vue"),
    props: true
  },
  {
    path: "/player/:playerId/historical",
    name: 'player-historical',
    component: () => import("../views/players/PlayerHistorical.vue"),
    props: true
  },
  {
    path: '/player/:playerId/matches',
    name: "player-matches",
    component: () => import('../views/matches/PlayerMatches.vue'),
    props: true
  },
  {
    path: '/player/:playerId/matches/:id',
    name: "match",
    component: () => import('../views/matches/Match.vue'),
    props: true
  },
  {
    path: "/comparer/players/:playerId?/:position?/:secondPlayerId?/:secondPlayerPosition?",
    name: "players-comparer",
    component: () => import('../views/comparers/PlayersComparer.vue'),
    props: (route) => {
      let params = route.params;
      return {
        playerId: params.playerId,
        position: parseNumber(params.position),
        secondPlayerId: params.secondPlayerId,
        secondPlayerPosition: parseNumber(params.secondPlayerPosition)
      }
    }
  },
  {
    path: "/comparer/player/:playerId/scouts",
    name: "player-scouters-comparer",
    component: () => import("../views/comparers/PlayerScoutsComparer.vue"),
    props: true
  },
  {
    path: "/shared/scout/:id",
    name: "shared-scout",
    component: () => import("../views/scouts/SharedScout.vue"),
    meta: { layout: 'full-screen', isPublic: true },
    props: true
  },
  {
    path: "/shared/player/:id",
    name: "shared-player",
    component: () => import("../views/players/SharedPlayer.vue"),
    meta: { layout: 'full-screen', isPublic: true },
    props: true
  },
  {
    path: "/shared/match/:id",
    name: "shared-match",
    component: () => import("../views/matches/SharedMatch.vue"),
    meta: { layout: 'full-screen', isPublic: true },
    props: true
  },
  {
    path: "/teams",
    name: "teams",
    component: () => import('../views/teams/Teams.vue')
  },
  {
    path: "/team/:id",
    name: "team",
    component: () => import("../views/teams/Team.vue"),
    props: true
  },
  {
    path: "/team/create",
    name: "create-team",
    component: () => import("../views/teams/CreateTeam.vue")
  },
  {
    path: "/team/:id/edit",
    name: "edit-team",
    component: () => import("../views/teams/EditTeam.vue"),
    props: true
  },
  {
    path: "/leagues",
    name: "leagues",
    component: () => import('../views/leagues/Leagues.vue')
  },
  {
    path: "/league/:id",
    name: "league",
    component: () => import("../views/leagues/League.vue"),
    props: true
  },
  {
    path: "/league/create",
    name: "create-league",
    component: () => import('../views/leagues/CreateLeague.vue')
  },
  {
    path: "/league/:id/edit",
    name: 'edit-league',
    component: () => import("../views/leagues/EditLeague.vue"),
    props: true
  },
  {
    path: "/league/export",
    name: "export-league",
    component: () => import("../views/leagues/ExportLeague.vue")
  },
  {
    path: "/league/import",
    name: "import-league",
    component: () => import("../views/leagues/ImportLeague.vue")
  },
  {
    path: "/records",
    name: "records",
    component: () => import('../views/records/Records.vue')
  },
  {
    path: "/record/:id",
    name: "record",
    component: () => import('../views/records/Record.vue'),
    props: true
  },
  {
    path: "/main-record",
    name: "main-record",
    component: () => import('../views/records/MainRecord.vue')
  },
  {
    path: "/club",
    name: "club",
    component: () => import("../views/club/Club.vue")
  },
  {
    path: "/club/build",
    name: "club-builder",
    component: () => import("../views/club/ClubBuilder.vue")
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/users/Users.vue')
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../views/users/User.vue'),
    props: true
  },
  {
    path: '/create-user',
    name: 'create-user',
    component: () => import('../views/users/CreateUser.vue')
  },
  {
    path: '/user/:id/update',
    name: 'update-user',
    component: () => import('../views/users/UpdateUser.vue'),
    props: true
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/identity/Profile.vue")
  },
  {
    path: "/metrics",
    name: "metrics",
    component: () => import("../views/metrics/Metrics.vue")
  },
  {
    path: '/back-room',
    name: "back-room",
    component: () => import("../views/back-room/BackRoom.vue")
  },
  {
    path: "/bulk-upload",
    name: "bulk-upload",
    component: () => import("../views/back-room/BulkUpload.vue")
  },
  {
    path: "/admin",
    name: "admin-panel",
    component: () => import("../views/admin/AdminPanel.vue"),
    meta: { roles: [roles.SUPER_ADMIN] }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/identity/Login.vue'),
    meta: { layout: 'full-screen', isPublic: true }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import('../views/identity/Logout.vue'),
    meta: { layout: 'full-screen', isPublic: true }
  },
  {
    path: "/reset-password",
    name: 'reset-password',
    component: () => import("../views/identity/ResetPassword.vue"),
    meta: { layout: "full-screen", isPublic: true }
  },
  {
    path: "/restore-password",
    name: 'restore-password',
    component: () => import("../views/identity/RestorePassword.vue"),
    meta: { layout: "full-screen", isPublic: true }
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("../views/error/Unauthorized.vue"),
    meta: { layout: 'full-screen', isPublic: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(vuexIdentityCreateRouterMiddleware);

export default router
